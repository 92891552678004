
import { defineStore } from 'pinia'
import { db } from '~/service/firebase_config'

import { StateEventModule, eventInfoData, settingsData, promptpayData} from '~/interfaces/store/event_interfaces'

import { useAppStateStore } from './appState'
import { useWishStore } from './wish'
import { useAuthStore } from './auth'
import { useTemplateStore } from './template'

import { collection, doc, getDoc, getDocs, where, query, setDoc } from 'firebase/firestore'

import _filter from 'lodash/filter.js'
import _size from 'lodash/size.js'
import _capitalize from 'lodash/capitalize.js'

export const useEventStore = defineStore({
  id: 'event',
  state: ():StateEventModule => {
    return {
      eventId: '' ,
      qrcodeId: '',

      eventData: {} as any,
      eventType : 'wedding', // wedding, brand
      information : {} as eventInfoData,
      settings : {} as settingsData,
      promptpay: {} as promptpayData,
      package : 'free' as 'free' | 'pro' | 'pro+',
      active : true,
      wishLimit: 5,
      
      notifyLineUsers: [],


      // ---- demo ----
      holderDemo: false,

      // ---- err ----
      errorEventId: '',
    }
  },
  actions: {
    async main() {
      return  await this.getEvent()
    },

    getIsDemoAppByQuery() {
      const $route = useRoute()
      const queryPayload:any = $route.query
      return queryPayload.demo
    },

    getQueryAndParams() {
      const $route = useRoute()
      // -- params & query ---
      const  eventIdParam = $route.params?.eventId
      const  qrcodeIdParam = $route.params?.qrcodeId

      const  eventIdQuery = $route.query?.eventId
      const  qrcodeIdQuery = $route.query?.qrcodeId

      if(eventIdParam || eventIdQuery) {
        return {
          eventId: eventIdParam || eventIdQuery,
          qrId: null
        }
      }

      if(qrcodeIdParam || qrcodeIdQuery) {
        return {
          qrcodeId: qrcodeIdParam || qrcodeIdQuery
        }
      }

      // --- query ----
      const queryString = decodeURIComponent(window.location.search).replace("?liff.state=", "");

      const params = new URLSearchParams(queryString);
      const paramsString = params.toString()

      // eventId=xxxx&demo=xxxx
      // --- query with node: eventId ---
      const splitArr = paramsString?.split('eventId=')[1]
      const targetEventId = splitArr?.split('&')[0]

      // --- query with node: eventId ---
      const qrcodeIdSplit = paramsString?.split('qrcodeId=')[1]
      const targetQrcodeId = qrcodeIdSplit?.split('&')[0]

      if(targetQrcodeId) {
        return {
          qrcodeId: targetQrcodeId
        }
      }

      if(targetEventId) {
        return {
          eventId: targetEventId,
          qrId: null
        }
      } 


      if(window.localStorage.getItem('eventId')) {
        return {
          eventId: window.localStorage.getItem('eventId'),
          qrId: null
        }
      }

      if(window.localStorage.getItem('qrcodeId')) {
        return {
          qrcodeId: window.localStorage.getItem('qrcodeId'),
          qrId: null
        }
      }
    },

    async getEventIdByQrcodeId(qrcodeId: string) {
      if(!qrcodeId) {
        return null
      }

      const eventsRef = doc(db, "qrcodes", qrcodeId);
      const response = await getDoc(eventsRef)

      if(response.exists()) {
        this.qrcodeId = qrcodeId
        return response.data()?.eventId 
      }
      return null 
    },

    async getEvent() {
      const $router = useRouter()
      const authStore = useAuthStore()
      const templateStore = useTemplateStore()
      const appStateStore = useAppStateStore()

      if(!this.eventId) {
        return 
      }

      const docRef = doc(db, 'events', this.eventId);
      const response = await getDoc(docRef)
        
      const data:any = response.data()

      if(!data) {
        this.eventId = ''
        return 
      }

      this.eventData = data
      this.$patch( { 
        ...data,
      })

      authStore.isDemoApp = data?.isDemo || false
      this.holderDemo = data?.isDemo || false
      
      appStateStore.theme = data?.settings?.theme || {
        templateId: "welcome_1",
        themeId: "default_welcome_1",
        image: "https://imagedelivery.net/G-5q2BMDs1WUCh3jAuvl7w/b458b8da-229b-4bf9-d6ee-c12ef03eaa00/original",
        fonts: {
          welcome: "Callem",
          thankyou: ""
        },
        colors: {
          main: "#0855CB",
          background: "#f1f5f9",
        }
      }

      this.wishLimit = data.settings?.writeLimit || 1
      templateStore.templateUsedIds = Object.keys(data.templates) || []
      return true
    },

    async getEventById(eventId: string) {
      
      if(!eventId) {
        return false
      }

      const docRef = doc(db, 'events', eventId);
      const response = await getDoc(docRef)
      const data:any = response.data()

      if(!data) {
        return null
      }


      return data

    },
  },
  getters: {
    date(state) { 
      return  state.information?.date
    },
    eventTime(state) { 
      return state.information?.eventTime
    },
    brideName(state) {
      return  _capitalize(state.information?.bride?.firstName) || ''
    },
    groomName(state) {
      return _capitalize(state.information?.groom?.firstName) || ''
    },
    eventTitle(state){
      return  state.information?.customTitle || state.information?.title
    },
    eventLocation(state){
      return  state.information?.location
    },
    eventProvince(state){
      return  state.information?.province
    },
    notifyLineUsers(state) {
      const lineObj = state?.eventData?.notify?.line || {}
      const filteredObj = Object.fromEntries(
        Object.entries(lineObj).filter(([key, value]) => value === true)
      );

      let list: Array<String> = []
      if(_size(filteredObj) > 0) {
        list = Object.keys(filteredObj) || []
      }

      return list
    }
  }
})