import { useEventStore } from '~/store/event'

import dayjs from 'dayjs'
import _includes from 'lodash/includes'
import liff from "@line/liff";

export default defineNuxtPlugin(async (nuxtApp) => {
  const eventStore = useEventStore()

  const eventData = computed(() => eventStore.eventData)
  const eventId = computed(() => eventData.value?.eventId)

  
  if (!process.client) {
    return
  }
  
  //check if http or local return
  const isLocalhost = _includes(window.location.href, 'localhost')
  if(isLocalhost) {
    return
  }
  
  // check if beta website
  const isBeta = _includes(window.location.href, 'https://wish-v2b.photowish.com') 
  if(isBeta) {
    return
  }
  
  // check is in client liff = true
  if(liff.isInClient()) {
    return
  }

  watch(() => eventData.value, () => {
    const eventDate = eventData.value?.information?.date
    const isFarEvent = checkEventDate(eventDate)
    const { userAgent } = navigator;

    // --- test ----
    const isTestApp = _includes(window.location.href, 'temporary-guest-test') 
    if(!liff.isInClient() && userAgent.includes("Line") && isTestApp) {
      return openTestLiff()
    }
    // --------------

    if(!liff.isInClient() && userAgent.includes("Line") && !isFarEvent) {
      return openProductionLiff()
    }

    if(!liff.isInClient() && userAgent.includes("Line") && isFarEvent) {
      return openBetaLiff()
    }

    if(isFarEvent) {
      pushToWebsiteBeta()
    }
  })


  const checkEventDate = (eventDate: string) => {
    const now = dayjs()
    const diffDate = Math.abs(now.diff(eventDate, 'day'))

    if(diffDate >= 8) {
      return true
    } else {
      return false
    }
  }


  const openProductionLiff = () => {
    window.location.href = `https://liff.line.me/2004940069-ApMgwb8O?eventId=${eventId.value}`
  }

  const openBetaLiff = () => {
    window.location.href = `https://liff.line.me/2004940069-6dL72NYd?eventId=${eventId.value}`
  }

  const openTestLiff = () => {
    window.location.href = `https://liff.line.me/2004940069-z1DopqLb?eventId=${eventId.value}`
  }

  const pushToWebsiteBeta = () => {
    window.location.href = `https://wish-v2b.photowish.com/?eventId=${eventId.value}`
  }
})