
import { useAuthStore } from '~~/store/auth';
import { useEventStore } from '~~/store/event';
import _includes from 'lodash/includes.js'
import liff from "@line/liff";

export default defineNuxtPlugin(async (nuxtApp) => {

  const authStore = useAuthStore()
  const eventStore = useEventStore()

  if(!process.client) {
    return 
  }

  const isEventNotFoundPage = _includes(window.location.href, '/eventNotFound') 
  if(isEventNotFoundPage) {
    return
  }

  const isQrcodetNotFoundPage = _includes(window.location.href, '/qrcodeNotFound') 
  if(isQrcodetNotFoundPage) {
    return
  }

  const isClosePage = _includes(window.location.href, '/close') 
  if(isClosePage) {
    return
  }

  console.log('init bootstrap & auto route')
  await init()
  const eventData = await getEventByLocalStorage()
  const isInClient:Boolean = await checkIsInLiffOrBrowser()

  if(isInClient && eventData) {
    
    eventStore.eventId = ''
    
    // ---- not work ---
    // eventStore.eventData = eventData
    // -----------------
    
    useRouter().push(`/close`)
    return 
  }

  watch(() => eventStore.eventData, () => {

    window.localStorage.removeItem('isDemo')
    window.localStorage.removeItem('qrcodeId')

    if(eventStore.eventData && eventStore.eventType === 'wedding') {
      const nodeName = `${eventStore?.eventId}:${authStore?.userId}:guestImage`
      window.localStorage.removeItem(nodeName)
    }
  })



  // ---- functions -----
  async function init() {
    const isDemo = eventStore.getIsDemoAppByQuery()
    eventStore.holderDemo = false

    if(isDemo) {
      window.localStorage.setItem('isDemo', 'true')
      eventStore.holderDemo = true
    }

    const result:any = eventStore.getQueryAndParams()

    if(result?.eventId) {
      window.localStorage.setItem('eventId', result?.eventId)
    }

    if(result?.qrcodeId) {
      window.localStorage.removeItem('eventId')
      window.localStorage.setItem('qrcodeId', result?.qrcodeId)
      const resultEventId:any = await eventStore.getEventIdByQrcodeId(result?.qrcodeId)

      // if qrcodeId not found eventId
      if(!resultEventId) {
        window.localStorage.removeItem('qrcodeId')
        window.location.href = window.location.origin + '/qrcodeNotFound'
        return 
      }
      // -----------------------------------------

      window.localStorage.setItem('eventId', resultEventId)
    }
  }

  async function getEventByLocalStorage () {
    const eventId = window.localStorage.getItem('eventId')
    if(eventId) {
      const data = await eventStore.getEventById(eventId)

      if(!data) {
        eventStore.eventId = ''
        window.localStorage.removeItem('eventId')
        useRouter().push(`/eventNotFound`)
        return null
      }
      eventStore.eventId = data?.eventId
      return data
    }

    const qrcodeId:any = window.localStorage.getItem('qrcodeId')
    if(qrcodeId) {
      window.localStorage.removeItem('eventId')
      const eventId = await eventStore.getEventIdByQrcodeId(qrcodeId)
      const data = await eventStore.getEventById(eventId)

      if(!data) {
        eventStore.eventId = ''
        eventStore.qrcodeId = ''
        window.localStorage.removeItem('qrcodeId')
        useRouter().push(`/qrcodeNotFound`)
        return null
      }

      eventStore.qrcodeId = qrcodeId
      eventStore.eventId = data?.eventId
      return data
    }

    return null
  }

  async function checkIsInLiffOrBrowser() {
    const { userAgent } = navigator;
    if(!liff.isInClient() && userAgent.includes("Line")) {
      return true
    } 
    return false
  }
})

